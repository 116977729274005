import React, { useCallback, useEffect, useState } from 'react'
import { Flex, Button, Text } from '@chakra-ui/react'
import ProgressBar from './application-progressbar'
import { UserProperty } from '../../generated/graphql'
import { ContainerHeading } from '../Headings'
import { capitalizeFirstLetter, getDomain } from '../../helpers'
import { OnBoardingModal } from '../Modals'
import { useNavigate } from 'react-router-dom'
import { AUTH_STORAGE_KEY, ONBOARDING_URL, USER_STORAGE_KEY } from '../../constants'
import LZString from 'lz-string'

type ProgressType = {
  property: UserProperty
  onboardingStepsCompleted: number
}
const ApplicationProgressContainer: React.FC<ProgressType> = ({
  property,
  onboardingStepsCompleted
}) => {
  const [propertyStep, setPropertyStep] = useState<number>(0)
  const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false)
  const [completedStepCount, setCompletedStepCount] = useState<number>(5)
  const [onboardingCompleted, setOnboardingCompleted] = useState<boolean>(false)
  const [failedCreditCheck, setFailedCreditCheck] = useState<boolean>(false)
  const navigate = useNavigate()

  const { installationComplete, installationProcessInitiated, recordId } = property

  useEffect(() => {
    if (onboardingStepsCompleted) {
      setCompletedStepCount(onboardingStepsCompleted)
    }
  }, [onboardingStepsCompleted])

  useEffect(() => {
    const orderStatus = property.orderStatus
    if (orderStatus === 'App assist') {
      setPropertyStep(0)
    }
    if (orderStatus === 'Agreement Signed' || orderStatus === 'Agreement Signed (incomplete)') {
      setPropertyStep(1)
    } 
    if (orderStatus === 'Once-Off Admin Fee Invoice Sent') {
      setPropertyStep(2)
    }
    if (property.installationComplete || property.installationProcessInitiated) {
      setPropertyStep(3)
    }

    if (
      orderStatus === 'Agreement Signed' ||
      orderStatus?.toLowerCase().includes('agreement signed') ||
      installationComplete ||
      installationProcessInitiated
    ) {
      setOnboardingCompleted(true)
    }

    setFailedCreditCheck(orderStatus === 'Credit Check Failed')
  }, [])

  const handleContinue = useCallback(() => {
    const userStorage = (localStorage.getItem(USER_STORAGE_KEY) ||
      sessionStorage.getItem(USER_STORAGE_KEY)) as string
    const user = userStorage ? JSON.parse(userStorage) : {}

    const domain = getDomain()
    const secure = domain === '.localhost' ? false : true

    // Set tokens in cookies
    document.cookie = `${AUTH_STORAGE_KEY}=${LZString.compressToBase64(
      JSON.stringify(user?.auth?.accessToken)
    )}; domain=${domain}; path=/; secure=${secure}; SameSite=None`

    switch (user?.info?.onboardingProgressCompletedStep) {
      case 5:
        window.open(`${ONBOARDING_URL}/credit-check`, '_blank')
        break

      case 6:
        if (user?.info?.entityType === 'private') {
          window.open(`${ONBOARDING_URL}/private-check/check/${property?.recordId}`, '_blank')
        } else {
          window.open(`${ONBOARDING_URL}/business-check/check/${property?.recordId}`, '_blank')
        }
        break

      case 7:
        if (property?.bankAccountNumber && property?.bankAccountNumber !== '') {
          window.open(`${ONBOARDING_URL}/private-check/upload/${property?.recordId}`, '_blank')
        } else {
          window.open(`${ONBOARDING_URL}/private-check/banking/${property?.recordId}`, '_blank')
        }
        break

     case 7.1:
        window.open(`${ONBOARDING_URL}/business-check/banking/${property?.recordId}`, '_blank')
        break
     case 7.2:
        window.open(`${ONBOARDING_URL}/document-upload/business/${property?.recordId}`, '_blank')
        break

      case 8:
        window.open(
          `${ONBOARDING_URL}/contract-summary/private/${property?.recordId}-${property?.orderNumber}`,
          '_blank'
        )
        break

      case 8.1:
        window.open(`${ONBOARDING_URL}/document-upload/business/${property?.recordId}`, '_blank')
        break

      case 8.2:
        window.open(
          `${ONBOARDING_URL}/contract-summary/business/${property?.recordId}-${property?.orderNumber}`,
          '_blank'
        )
        break

      case 9:
        if (property?.creditCheckPassed === null) {
          window.open(`${ONBOARDING_URL}/document-upload/business/${property?.recordId}`, '_blank')
        } else {
          window.open(`${ONBOARDING_URL}/document-upload/private/${property?.recordId}`, '_blank')
        }
        break
      default:
        console.error('No onboarding step found', user?.info?.onboardingProgressCompletedStep)
        window.open(`${ONBOARDING_URL}/credit-check`, '_blank')
        break
    }
  }, [])

  return (
    <Flex
      w={'100%'}
      p="25px"
      h="100%"
      alignItems={'center'}
      justifyContent={'center'}
      rounded={'8px'}
      maxH={'100%'}
      display="flex"
      bgColor="brand.neutral"
      direction={'column'}
      key={`${property.propertyLat}-${property.recordId}`}
      gap="25px"
      shadow={'md'}
      pb="50px"
    >
      <ContainerHeading title="Application progress" />
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        direction={['column', 'column', 'row', 'row']}
        gap="25px"
        pb={5}
      >
        <Flex
          textAlign={'center'}
          alignItems={'center'}
          justifyContent={'center'}
          direction={['column', 'column', 'row', 'row']}
          gap="25px"
        >
          <Flex direction={'column'} gap="10px">
            <Text fontSize={'14px'} color={'brand.text.primary'}>
              <b>Type:</b>{' '}
              {capitalizeFirstLetter(
                property?.subscription?.subscriptionOption?.replace('2023_', '') as string
              )}{' '}
              subscription
            </Text>
            <Text fontSize={'14px'} color={'brand.text.primary'}>
              <b>Address:</b>
            </Text>
            <Text fontSize={'14px'} color={'brand.text.primary'}>
              {property.fullAddress}
            </Text>
            <Text fontSize={'14px'} color={'brand.text.primary'}>
              <b>Order status:</b> {property.orderStatus?.replace(' (incomplete)', '')}
            </Text>
            <Text fontSize={'14px'} color={'brand.text.primary'}>
              <b>Installation date:</b>{' '}
              {property.installationDate ? property.installationDate : 'Not set'}
            </Text>
          </Flex>
        </Flex>
        <Flex w={'100%'} alignItems={'center'} justifyContent="center">
          <ProgressBar stepsCompleted={propertyStep} />
        </Flex>
      </Flex>

      {completedStepCount < 9 && !onboardingCompleted && !failedCreditCheck && (
        <Button variant={'dark'} onClick={handleContinue}>
          Continue onboarding
        </Button>
      )}

      {/* 
        This should only be allowed if it's the current property being onboarded. 
        Currently if you click on "optional upload" it loads the onboarding app at the onboarding step for the last property. 
        Meaning it will go to the last step of the last property that was onboarded, and not the optional uploads step for this property/order
        TODO: Find a way to allow uploads to the property being selected

        {onboardingCompleted && !failedCreditCheck && (
        <Button variant={'dark'} onClick={handleContinue}>
          Optional uploads
        </Button>
      )} */}

      {showOnboardingModal && (
        <OnBoardingModal
          isOpen={showOnboardingModal}
          onClose={() => setShowOnboardingModal(false)}
          recordId={property.recordId}
          orderNumber={property.orderNumber}
          step={completedStepCount}
        ></OnBoardingModal>
      )}
    </Flex>
  )
}

export default ApplicationProgressContainer
